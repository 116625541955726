"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const element_vir_1 = require("element-vir");
const reduxular_1 = require("reduxular");
const store_1 = require("../../services/store");
const utilities_1 = require("../../services/utilities");
const paths_1 = require("../../utilities/routes/paths");
require("./jo-companies-sidebar"); // TODO see if we can move this into jo-router-jill
require("./jo-login");
require("./jo-reset-password");
require("./jo-router-customer.element");
require("./jo-router-jill.element");
require("./jo-send-forgot-password-email");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    currentCallInformation: store_1.InitialGlobalState.currentCallInformation,
    isMobileDevice: store_1.InitialGlobalState.isMobileDevice,
    loading: {
        joCompaniesLoading: true,
        joHomeLoading: true,
    },
    mobileTopBarTitle: store_1.InitialGlobalState.mobileTopBarTitle,
    route: store_1.InitialGlobalState.route,
    showSidebar: store_1.InitialGlobalState.showSidebar,
    twilioDevice: store_1.InitialGlobalState.twilioDevice,
};
// TODO keep an eye on this queue thing...it's unfortunate, but I think we need it to ensure that asynchronous renders are done in order
let renderQueue = [];
let renderQueueLock = false;
class JORouter extends HTMLElement {
    constructor() {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, async (state) => {
            renderQueue = [
                ...renderQueue,
                state,
            ];
            if (renderQueueLock === false) {
                renderQueueLock = true;
                for (let i = 0; i < renderQueue.length; i++) {
                    const nextStateToRender = renderQueue[i];
                    if (nextStateToRender == null)
                        throw new Error('nextStateToRender is null');
                    (0, lit_html_1.render)(await this.render(nextStateToRender), this);
                }
                renderQueue = [];
                renderQueueLock = false;
            }
        }, this, utilities_1.setPropsReducer);
        // TODO I believe we need this timeout right now to ensure that the connectedCallback runs to completion
        // TODO before the parent rerenders
        setTimeout(() => {
            (0, utilities_1.raiseAction)(this, {
                type: 'CHANGE_ROUTE',
                pathname: window.location.pathname,
                search: window.location.search,
                previousFullPath: window.location.pathname,
            });
        });
        window.addEventListener('popstate', (e) => {
            if (this.store.route.blockingNavigation === true) {
                return;
            }
            const shouldBlockCallScreenNavigation = (0, utilities_1.getIsOnCallScreen)(this.store.route);
            if (shouldBlockCallScreenNavigation === true) {
                (0, utilities_1.joAlert)('Navigation', 'You must finish the current call');
                (0, utilities_1.raiseCustomEvent)(this, 'blockingnavigationchanged', true);
                window.history.forward();
                // this timeout is here to ensure that blockingNavigationChanged on the global store
                // is not set to false before the popstate event from the window.history.forward occurs (I think)
                // without the setTimeout, blocking navigation will be set to false too soon, and thus the joAlert
                // is shown twice
                setTimeout(() => {
                    (0, utilities_1.raiseCustomEvent)(this, 'blockingnavigationchanged', false);
                });
                return;
            }
            (0, utilities_1.raiseAction)(this, {
                type: 'CHANGE_ROUTE',
                pathname: window.location.pathname,
                search: window.location.search,
            });
        });
    }
    set props(props) {
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    // TODO try to make the conditional importing declarative
    // TODO attempt to combine the custom element definition checks with importing
    async render(state) {
        if (state.authenticatedUser !== 'NOT_SET' && (0, utilities_1.userIsJill)(state.authenticatedUser) === true) {
            await importIfNecessaryForJill(state.route);
        }
        if (state.authenticatedUser !== 'NOT_SET' &&
            (0, utilities_1.userIsJill)(state.authenticatedUser) === false) {
            await importIfNecessaryForCustomer(state.route);
        }
        return (0, element_vir_1.html) `
            <style>
                .jo-router--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-router--grid {
                    display: grid;
                    grid-template-rows: min-content auto;
                    height: 100vh;
                    width: 100vw;
                    box-sizing: border-box;
                    overflow: hidden;
                }

                .jo-router--sub-main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    overflow: hidden;
                }

                .jo-router--companies-sidebar-container {
                    box-sizing: border-box;
                    flex: 1;
                    max-width: 20%;
                }

                .jo-router--main-content-container {
                    flex: 6;
                    background-color: rgb(0, 0, 0, 0.02);
                    overflow: auto;
                    box-sizing: border-box;
                }

                .jo-router--top-bar-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    overflow-x: ${state.isMobileDevice ? 'scroll' : 'hidden'};
                }
            </style>

            <div class="jo-router--main-container">
                <div class="jo-router--grid">
                    <div class="jo-router--top-bar-container">
                        <jo-top-bar
                            ?hidden=${shouldShowJillTopBar(state) === false}
                            .authenticatedUser=${state.authenticatedUser}
                            .route=${state.route}
                            .isMobileDevice=${state.isMobileDevice}
                        ></jo-top-bar>
                    </div>

                    <div class="jo-router--sub-main-container">
                        ${shouldShowCompaniesSidebar(state)
            ? (0, element_vir_1.html) `
                                  <div class="jo-router--companies-sidebar-container">
                                      <jo-companies-sidebar
                                          ?hidden=${state.route.pathname === '/login'}
                                          .companyId=${state.route.companyId === 'NOT_SET'
                ? 'NOT_SET'
                : state.route.companyId}
                                      ></jo-companies-sidebar>
                                  </div>
                              `
            : ''}

                        <div class="jo-router--main-content-container">
                            ${(0, element_vir_1.renderIf)(state.route.pathname === '/login', (0, element_vir_1.html) `
                                    <jo-login .isMobileDevice=${state.isMobileDevice}></jo-login>
                                `)}
                            ${(0, element_vir_1.renderIf)(state.route.pathname === '/forgot-password', (0, element_vir_1.html) `
                                    <jo-send-forgot-password-email
                                        .isMobileDevice=${state.isMobileDevice}
                                    ></jo-send-forgot-password-email>
                                `)}
                            ${(0, element_vir_1.renderIf)(state.route.pathname === '/reset-password', (0, element_vir_1.html) `
                                    <jo-reset-password
                                        .jwtToken=${state.route.search.slice(1)}
                                        .isMobileDevice=${state.isMobileDevice}
                                    ></jo-reset-password>
                                `)}
                            ${state.authenticatedUser !== 'NOT_SET' &&
            (0, utilities_1.userIsJill)(state.authenticatedUser) === true
            ? (0, element_vir_1.html) `
                                      <jo-router-jill
                                          .props=${{
                authenticatedUser: state.authenticatedUser,
                currentCallInformation: state.currentCallInformation,
                route: state.route,
                twilioDevice: state.twilioDevice,
            }}
                                          @jo-home-loaded=${(e) => {
                this.store.loading = {
                    ...this.store.loading,
                    joHomeLoading: !e.detail,
                };
            }}
                                          @jo-companies-loaded=${(e) => {
                this.store.loading = {
                    ...this.store.loading,
                    joCompaniesLoading: !e.detail,
                };
            }}
                                      ></jo-router-jill>
                                  `
            : ''}
                            ${state.authenticatedUser !== 'NOT_SET' &&
            (0, utilities_1.userIsJill)(state.authenticatedUser) === false
            ? (0, element_vir_1.html) `
                                      <jo-router-customer
                                          .props=${{
                authenticatedUser: state.authenticatedUser,
                isMobileDevice: state.isMobileDevice,
                mobileTopBarTitle: state.mobileTopBarTitle,
                route: state.route,
                showSidebar: state.showSidebar,
            }}
                                      ></jo-router-customer>
                                  `
            : ''}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-router', JORouter);
async function importIfNecessaryForJill(route) {
    if (route.pathname === '/') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-home/jo-home')));
    }
    if (route.pathname === '/quick-text-dashboard') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: “eager” */ './jo-quick-text/jo-quick-text-dashboard.element')));
    }
    if (route.pathname === '/register-company') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: “eager” */ './jo-register-company/jo-register-company')));
    }
    if (route.pathname === '/chat-dashboard') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-chat/jo-chat-dashboard/jo-chat-dashboard')));
    }
    if (route.pathname === '/billing-report') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-billing-report/jo-billing-report')));
    }
    if (route.pathname === '/chat-settings') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-chat/jo-chat-settings/jo-chat-settings')));
    }
    if (route.pathname === '/company/call-instructions/chat-widget-script-generator') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-chat/jo-chat-widget-settings/jo-chat-widget-settings')));
    }
    if (route.pathname === '/control-panel') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-control-panel.element')));
    }
    if (route.pathname === '/incoming-queue-report') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-incoming-queue-report/jo-incoming-queue-report')));
    }
    if (route.pathname === '/graphql') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-graphql-playground')));
    }
    if (route.pathname === '/jills') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-jills')));
    }
    if (route.pathname === '/teams') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-teams')));
    }
    if (route.pathname === '/high-level-report') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-high-level-report.element')));
    }
    if (route.pathname === '/jill-high-level-report') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-jill-high-level-report/jo-jill-high-level-report')));
    }
    if (route.pathname === '/jill') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-jill/jo-jill.element')));
    }
    if (route.pathname === '/outbound-dashboard') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: “eager” */ './jo-outbound/jo-outbound-dashboard/jo-outbound-dashboard')));
    }
    if (route.pathname === '/team') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-team')));
    }
    if (route.pathname === '/company') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company/jo-company')));
    }
    if (route.pathname.includes('/company') === true) {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company/jo-company-nav-bar.element')));
    }
    if (route.pathname === '/companies') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-companies')));
    }
    if (route.pathname === '/pods') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-pods')));
    }
    if (route.pathname === '/company/employees') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company/jo-company-employees')));
    }
    if (route.pathname === '/company/employee/edit' ||
        route.pathname === '/company/employee/create') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company/jo-company-employee')));
    }
    if (route.pathname === '/company/company-details') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company-details/jo-company-details')));
    }
    if (route.pathname === '/users') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-users')));
    }
    if (route.pathname === '/user' || route.pathname === '/user/create') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-user/jo-user')));
    }
    if (route.pathname === '/company/contact-list') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company/jo-company-contacts/jo-company-contacts')));
    }
    if (route.pathname === '/company/action-items') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-action-items/jo-action-items')));
    }
    if (route.pathname === '/company/call-instruction') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company/jo-company-call-instruction')));
    }
    if (route.pathname === '/company/crew-cal/crew-cal-management') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-crew-cal/jo-company-crew-cal-management/jo-company-crew-cal-management')));
    }
    if (route.pathname === '/company/crew-cal/calendars') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-crew-cal/jo-company-google-calendars/jo-google-calendars-for-company/jo-google-calendars-for-company')));
    }
    if (route.pathname === '/company/contact') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-contact/jo-contact-save')));
    }
    if (route.pathname === '/company/call-instructions/general-setup') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-call-instructions/jo-company-call-instructions-general-setup')));
    }
    if (route.pathname === '/company/call-instructions/call-instruction-pages') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-call-instructions/jo-company-call-instructions-pages')));
    }
    if (route.pathname === '/company/call-instructions/services-pricing') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-services-pricing/jo-company-services-pricing')));
    }
    if (route.pathname === '/company/call-instructions/call-section-notes') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-call-section-notes/jo-company-call-section-notes')));
    }
    if (route.pathname === '/company/call-instructions/transfers') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company-transfers/jo-company-transfers')));
    }
    if (route.pathname === '/company/call-instructions/quick-notes') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company/jo-company-quick-notes/jo-company-quick-notes')));
    }
    if (route.pathname === paths_1.companyUserActivityRoutePath) {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company/jo-company-user-activity-report/jo-company-user-activity-report')));
    }
    if (route.pathname === '/company/billing') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-billing/jo-billing')));
    }
    if (route.pathname === '/company/billing/logs') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ '../main-pages/billing-logs/logs-container-page/jo-company-logs-container-page')));
    }
    if (route.pathname === '/company/billing/invoice') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-billing/jo-invoice/jo-invoice')));
    }
    if (route.pathname === '/yearly-report') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-yearly-report')));
    }
    if (route.pathname === '/jills-pods') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-jills-pods/jo-jills-pods')));
    }
    if (route.pathname === '/change-user-password') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-change-user-password')));
    }
    if (route.pathname === '/billing-packages') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-billing-packages')));
    }
    if (route.pathname.includes('/minutes-package')) {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-minutes-package')));
    }
    if (route.pathname.includes('/company/voicemails')) {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company-voicemails/jo-company-voicemails')));
    }
    if (route.pathname === '/personal-admin-dashboard') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-personal-admin-dashboard/jo-personal-admin-dashboard')));
    }
    if (route.pathname === '/company/flagged-action-items') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-action-item-flags-table/jo-action-item-flags-table')));
    }
    if (route.pathname === '/scheduled-down-times') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-scheduled-down-times/jo-scheduled-down-times')));
    }
    if (route.pathname === '/chat-stats-overview') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-chat-stats/jo-chat-stats-overview')));
    }
    if (route.pathname === '/profile-settings') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ '../main-pages/profile-settings-page/profile-settings-page.element')));
    }
    if (route.pathname === '/queue-settings') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ '../main-pages/settings-pages/queue-status-box-settings-page/jo-queue-status-box-settings-page.element')));
    }
}
async function importIfNecessaryForCustomer(route) {
    if (route.pathname === '/' || route.pathname === '/customer/dashboard') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-customer/jo-customer-dashboard')));
    }
    if (route.pathname === '/customer/action-items') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-customer/jo-customer-action-items')));
    }
    if (route.pathname === '/customer/action-item') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-customer/jo-customer-action-item/jo-customer-action-item')));
    }
    if (route.pathname === '/graphql') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-graphql-playground')));
    }
    if (route.pathname === '/company/call-instruction') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company/jo-company-call-instruction')));
    }
    if (route.pathname === '/customer/call-instructions/general-setup') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-customer/jo-customer-call-instructions/jo-customer-call-instructions-general-setup')));
    }
    if (route.pathname === '/customer/call-instructions/script-pages') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-customer/jo-customer-call-instructions/jo-customer-call-instructions-script-pages')));
    }
    if (route.pathname === '/customer/call-instructions/services-pricing') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-customer/jo-customer-call-instructions/jo-customer-call-instructions-services-pricing')));
    }
    if (route.pathname === '/customer/call-instructions/quick-notes') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company/jo-company-quick-notes/jo-company-quick-notes')));
    }
    if (route.pathname === '/customer/contact-list') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-customer/jo-customer-contacts')));
    }
    if (route.pathname === '/customer/contact') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-contact/jo-contact-save')));
    }
    if (route.pathname === '/customer/company-profile') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-customer/jo-customer-company-profile')));
    }
    if (route.pathname === '/customer/users') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-customer/jo-customer-employees')));
    }
    if (route.pathname === '/customer/employee/edit') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company/jo-company-employee')));
    }
    if (route.pathname === '/customer/billing') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-customer/jo-customer-billing')));
    }
    if (route.pathname === '/customer/logs') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ '../main-pages/billing-logs/logs-container-page/jo-company-logs-container-page')));
    }
    if (route.pathname === '/customer/chat-widget-script-generator') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-chat/jo-chat-widget-settings/jo-chat-widget-settings')));
    }
    if (route.pathname === '/customer/invoice') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-billing/jo-invoice/jo-invoice')));
    }
    if (route.pathname === '/customer/call-log') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-call-log/jo-company-call-log.element')));
    }
    if (route.pathname === '/customer/company-calendars' &&
        doesNotContainGoogleAuthCodeInSearchParams()) {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-company-crew-cal/jo-company-google-calendars/jo-google-calendar-authorization/jo-google-calendar-authorization')));
    }
    if (route.pathname === '/customer/crew-cal-management') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-customer/jo-customer-crew-cal-management')));
    }
    if (route.pathname === '/customer/outbound') {
        await Promise.resolve().then(() => __importStar(require(
        /* webpackMode: "eager" */ './jo-outbound/jo-customer-outbound-dashboard/jo-customer-outbound-dashboard')));
    }
    if (route.pathname === '/customer/voicemail') {
        await Promise.resolve().then(() => __importStar(require(/* webpackMode: "eager" */ './jo-company-voicemails/jo-company-voicemails')));
    }
}
function doesNotContainGoogleAuthCodeInSearchParams() {
    return window.location.search === '';
}
function shouldShowCompaniesSidebar(state) {
    return (state.authenticatedUser !== 'NOT_SET' &&
        (0, utilities_1.userIsJill)(state.authenticatedUser) === true &&
        state.route.pathname !== '/login' &&
        state.route.pathname !== '/forgot-password' &&
        state.route.pathname !== '/reset-password' &&
        state.route.pathname !== '/call-screen' &&
        state.route.pathname !== '/action-item' &&
        state.route.pathname !== '/chat-dashboard');
}
function shouldShowJillTopBar(state) {
    return (state.authenticatedUser !== 'NOT_SET' &&
        (0, utilities_1.userIsJill)(state.authenticatedUser) === true &&
        state.route.pathname !== '/login' &&
        state.route.pathname !== '/forgot-password' &&
        state.route.pathname !== '/reset-password');
}
